import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import i18n from 'i18n'
import { RootState } from 'app/store'
import { ErrorData, ErrorState, setStatus } from 'features/error/errorSlice'
import { doLogout } from 'features/auth/authSlice'
import { getUserPermissionsAPI, getUserProfileAPI } from 'utils/api'
import { getCompany, setCompany } from 'utils/company'
import type {
  CountryCode,
  PhoneCode,
  CurrencyCode,
  CurrencySymbol
} from 'utils/constant/country'

export interface CompanyData {
  id: number
  name: string
  country_code: CountryCode
  currency: {
    code: CurrencyCode
    symbol: CurrencySymbol
  }
  phone_code: PhoneCode
  has_master_dashboard: boolean
}

export interface PermissionData {
  roleName: string
  permissions: string[]
}

export interface ProfileState {
  companies: CompanyData[]
  errors: ErrorData
  isLoading: boolean
  hasMasterDashboardAccess: boolean
  permissions: {
    errors: ErrorData
    isLoading: boolean
    data: PermissionData
  }
  companyId: null | number
}

const initialState: ProfileState = {
  companies: [],
  errors: null,
  isLoading: false,
  hasMasterDashboardAccess: false,
  permissions: {
    errors: null,
    isLoading: false,
    data: {} as PermissionData
  },
  companyId: getCompany()
}

export const getUserProfile = createAsyncThunk(
  'profile/getProfile',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getUserProfileAPI()
      const { companies } = response.data
      if (companies.length === 0) {
        dispatch({ type: doLogout.fulfilled.type })
        const err = {
          response: {
            status: 1001,
            data: {
              message: i18n.t('errors.no_companies')
            }
          }
        }
        throw err
      }
      return response
    } catch (error) {
      const { status, data } = error.response
      if (data || status) {
        dispatch(setStatus({ status, data }))
        return rejectWithValue({ data, status })
      }
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const getUserPermissions = createAsyncThunk(
  'profile/getPermissions',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await getUserPermissionsAPI()
    } catch (error) {
      const { status, data } = error.response
      if (data || status) {
        dispatch(setStatus({ status, data }))
        return rejectWithValue({ data, status })
      }
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    setCompanyId: (state, action) => {
      state.companyId = action.payload
      setCompany(action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        const {
          companies,
          has_master_dashboard_access = false,
          parent_company_name = ''
        } = action.payload.data
        state.isLoading = false
        state.errors = null
        let allCompanies = companies
        if (has_master_dashboard_access) {
          const parentCompany = [
            {
              ...companies[0],
              name: parent_company_name,
              id: 0,
              has_master_dashboard: true
            }
          ]
          allCompanies = [...parentCompany, ...companies]
        }
        state.companies = allCompanies
        state.hasMasterDashboardAccess = has_master_dashboard_access
        const findCompany = allCompanies.find(
          (company: CompanyData) => company.id === getCompany()
        )
        if (!findCompany) {
          const companyId = allCompanies[0].id
          setCompany(companyId)
          state.companyId = companyId
        }
      })
      .addCase(
        getUserProfile.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.isLoading = false
          state.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
      .addCase(getUserPermissions.pending, (state) => {
        state.permissions.isLoading = true
      })
      .addCase(getUserPermissions.fulfilled, (state, action) => {
        const { permissions, role_name } = action.payload.data
        state.permissions.isLoading = false
        state.permissions.errors = null
        state.permissions.data.permissions = permissions
        state.permissions.data.roleName = role_name
      })
      .addCase(
        getUserPermissions.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.permissions.isLoading = false
          state.permissions.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
  }
})

export const { setCompanyId } = profileSlice.actions

export const profileSelector = (state: RootState) => state.profile

export const permissionSelector = (state: RootState) =>
  state.profile.permissions

export const companySelector = (state: RootState) => {
  return state.profile.companies.find(
    (company: CompanyData) => company.id === state.profile.companyId
  )
}

export const isMasterCompanySelector = (state: RootState) => {
  return state.profile.companyId === 0
}

export const masterCompaniesSelector = (state: RootState) => {
  return state.profile.companies.filter(
    (company: CompanyData) => company.has_master_dashboard
  )
}

export const masterCompaniesWithoutParentSelector = (state: RootState) => {
  return state.profile.companies.filter(
    (company: CompanyData) => company.has_master_dashboard && company.id > 0
  )
}

export default profileSlice.reducer
