import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'navigator'],

  // keys or params to lookup language from
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',
  lookupQuerystring: 'locale',

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      vi: ['vi-VN'],
      id: ['id-ID'],
      en: ['en-US'],
      'en-SG': ['en-US'],
      'en-ID': ['en-US'],
      default: ['en-US']
    },
    supportedLngs: ['en-US', 'en-SG', 'en-ID', 'id-ID', 'vi-VN', 'id', 'vi', 'en'],
    detection: langDetectorOptions,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
