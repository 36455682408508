export type CountryCode = 'ID' | 'SG' | 'VN'
export type PhoneCode = '62' | '65' | '84'

export type CurrencyCode = 'IDR' | 'SGD' | 'VND'
export type CurrencySymbol = 'Rp' | 'S$' | '₫'

export type SymbolSeparator = '.' | ','

export type LocaleCode = 'en-US' | 'id-ID' | 'vi-VN'
export type Locale = {
  id: number
  name: string
  code: LocaleCode
}

export type UTC = '+07:00' | '+08:00'
export type UTCH = 7 | 8

export type LatLng = { lat: number; lng: number }
export type LatLngBounds = {
  north: number
  south: number
  east: number
  west: number
}

export type BillingType = 'CC' | 'MONTHLY_BILLING' | 'PREPAID'
export type CCRegistrationStatus = 'success' | 'pending' | 'failed' | ''

export const CCBillingType = 'CC'
export const MonthlyBillingType = 'MONTHLY_BILLING'
export const PrepaidBillingType = 'PREPAID'

export const IDCountryCode = 'ID'
export const IDPhoneCode = '65'
export const IDCurrencyCode = 'IDR'
export const IDCurrencySymbol = 'Rp'

export const SGCountryCode = 'SG'
export const SGPhoneCode = '62'
export const SGCurrencyCode = 'SGD'
export const SGCurrencySymbol = 'S$'

export const VNCountryCode = 'VN'
export const VNPhoneCode = '84'
export const VNCurrencyCode = 'VND'
export const VNCurrencySymbol = '₫'
export const defaultCurrencyCode: Record<CountryCode, CurrencyCode> = {
  ID: IDCurrencyCode,
  SG: SGCurrencyCode,
  VN: VNCurrencyCode
}
export const defaultCurrencySymbol: Record<CountryCode, CurrencySymbol> = {
  ID: IDCurrencySymbol,
  SG: SGCurrencySymbol,
  VN: VNCurrencySymbol
}

export const commaSeparator = ','
export const dotSeparator = '.'
export const defaultThousandSeparator: Record<CountryCode, SymbolSeparator> = {
  ID: dotSeparator,
  SG: commaSeparator,
  VN: dotSeparator
}

export const enUS = 'en-US'
export const idID = 'id-ID'
export const viVN = 'vi-VN'
export const defaultLocale: Partial<Record<CountryCode, LocaleCode>> = {
  SG: enUS,
  VN: viVN
}
export const defaultCurrencyLocale: Record<CountryCode, LocaleCode> = {
  SG: enUS,
  ID: idID,
  VN: viVN
}
export const locales: Locale[] = [
  {
    id: 1,
    name: 'English',
    code: enUS
  },
  {
    id: 2,
    name: 'Bahasa Indonesia',
    code: idID
  },
  {
    id: 2,
    name: 'Tiếng Việt',
    code: viVN
  }
]

export const UTC7 = '+07:00'
export const UTC7H = 7
export const UTC8 = '+08:00'
export const UTC8H = 8
export const defaultUTC: Record<CountryCode, UTC> = {
  ID: UTC7,
  SG: UTC8,
  VN: UTC7
}
export const defaultUTCH: Record<CountryCode, UTCH> = {
  ID: UTC7H,
  SG: UTC8H,
  VN: UTC7H
}

export const defaultLatLng: Record<CountryCode, LatLng> = {
  // National Museum of Indonesia
  ID: {
    lat: -6.175211063645156,
    lng: 106.8271635257335
  },
  // National Museum of Singapore
  SG: {
    lat: 1.2967460587626092,
    lng: 103.84846171936132
  },
  // National Museum of Vietnam
  VN: {
    lat: 21.026769414966065,
    lng: 105.8583544861341
  }
}

/**
 * use google map geocode API to get the bounds
 * https://developers.google.com/maps/documentation/javascript/examples/geocoding-component-restriction
 * https://jsfiddle.net/gh/get/library/pure/googlemaps/js-samples/tree/master/dist/samples/geocoding-component-restriction/jsfiddle
 */
export const defaultLatLngBounds: Record<CountryCode, LatLngBounds> = {
  ID: {
    north: 6.216999899999999,
    south: -11.1082999,
    west: 94.7351,
    east: 141.0425
  },
  SG: {
    south: 1.1496,
    north: 1.4784001,
    west: 103.594,
    east: 104.0945001
  },
  VN: {
    south: 8.59975962975,
    north: 23.3520633001,
    west: 102.170435826,
    east: 109.33526981
  }
}
