/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import React from 'react'
import tw from 'twin.macro'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Button from '@asphalt-react/button'
import { Text } from '@asphalt-react-v2/typography'

import Img from './Img'
import { errorStatusSelector } from 'features/error/errorSlice'

type InternalServerProps = {
  noBorder?: boolean
  version?: 'v1' | 'v2'
  dataTestid?: string
  customCTA?: React.ReactNode
}

const InternalServer = ({
  noBorder,
  version,
  dataTestid,
  customCTA
}: InternalServerProps) => {
  const { t } = useTranslation()
  const { status } = useSelector(errorStatusSelector)

  const refreshPage = () => {
    if (status === 1001) {
      window.location.href = '/login'
    } else {
      window.location.reload()
    }
  }

  let title
  let desc
  if (status === 429) {
    title = t('errors.429')
    desc = t('errors.429_desc')
  } else if (status === 1001) {
    title = t('errors.account_inactive')
    desc = t('errors.no_companies')
  } else {
    title = t('errors.500')
    desc = t('errors.500_desc')
  }

  if (version === 'v2') {
    return (
      <div
        data-testid={dataTestid}
        tw="mb-8 p-6 xl:(p-12 flex-row) flex flex-wrap flex-col w-full items-center"
      >
        <div tw="w-full lg:(w-2/5 mx-auto) mb-4">
          <Img
            src="/images/error/troubleshoot.png"
            width="474"
            height="242"
            tw="w-full"
          />
        </div>
        <div tw="w-full text-center">
          <div tw="mb-2 w-full">
            <Text muted bold size="m">
              {title}
            </Text>
          </div>
          <div tw="w-full whitespace-pre-wrap">
            <Text muted size="m" as="span">
              {desc}
            </Text>
          </div>
          {customCTA}
        </div>
      </div>
    )
  }
  return (
    <div
      tw="w-full flex flex-wrap items-center xxl:(px-64 py-40)"
      css={[!noBorder && tw`border border-gray10`]}
    >
      <div tw="w-full p-8 lg:(w-1/2 p-12)">
        <Img src="/images/error/troubleshoot.png" alt="internal server error" />
      </div>
      <div tw="w-full mb-8 text-center box-border px-8 lg:(w-1/2 text-left px-12 mb-0)">
        <h3 tw="md:text-28 font-semibold w-full">{title}</h3>
        <p tw="text-sm md:text-lg w-full mb-4">{desc}</p>
        <Button outline size="m" onClick={refreshPage}>
          {status === 1001
            ? t('shared.ok_button')
            : t('shared.page_refresh_button')}
        </Button>
      </div>
    </div>
  )
}

export default InternalServer
