import React, { createContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '@asphalt-react/loader'

import {
  getUserPermissions,
  getUserProfile,
  permissionSelector,
  profileSelector
} from 'features/profile/profileSlice'
import GoCorp from 'components/SVG/GoCorpLogo'
import ScreenCenterLayout from 'components/Layout/ScreenCenterLayout'
import { isLoggedIn } from 'features/auth/authSlice'
import InternalServer from 'components/InternalServer'

type UserProfileProviderProps = {
  children: React.ReactNode
}

const UserProfileContext = createContext(null)

export function UserProfileProvider({ children }: UserProfileProviderProps) {
  const dispatch = useDispatch()
  const {
    isLoading: isLoadingUserProfile,
    companies,
    errors: errUserProfile
  } = useSelector(profileSelector)
  const {
    isLoading: isLoadingUserPermission,
    errors: errUserPermission,
    data
  } = useSelector(permissionSelector)
  const authenticated = useSelector(isLoggedIn)

  const hasAccess = data?.permissions?.length > 0 && companies.length > 0

  useEffect(() => {
    if (authenticated) {
      dispatch(getUserProfile())
      dispatch(getUserPermissions())
    }
  }, [authenticated, dispatch])

  if (isLoadingUserPermission || isLoadingUserProfile) {
    return (
      <ScreenCenterLayout>
        <div style={{ textAlign: 'center' }}>
          <GoCorp size="l" />
          <div style={{ width: '100%', marginTop: '16px' }}>
            <Loader />
          </div>
        </div>
      </ScreenCenterLayout>
    )
  } else if (errUserProfile || errUserPermission) {
    return (
      <ScreenCenterLayout>
        <InternalServer noBorder />
      </ScreenCenterLayout>
    )
  }

  return (
    <UserProfileContext.Provider value={null}>
      {(hasAccess || !authenticated) && children}
    </UserProfileContext.Provider>
  )
}
