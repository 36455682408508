import React, { lazy, Suspense, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'

import configuration from 'configuration'
import { useSessionSync } from 'app/hooks/useSessionSync'
import { usePageviews } from 'app/hooks/useGoogleAnalytics'
import { getGroups } from 'features/groups/groupSlice'
import { isLoggedIn, authSelector } from 'features/auth/authSlice'
import { getNotificationCount } from 'features/notification/notificationSlice'
import { profileSelector } from 'features/profile/profileSlice'
import { getHelpCenterList } from 'features/helpcenter/helpCenterSlice'
import { getCompanyDetail } from 'features/company/companySlice'

type LayoutProps = {
  children?: React.ReactNode
}

type RouterLayoutProps = LayoutProps & {
  path?: string
  exact?: boolean
  hasWhiteBackground?: boolean
}

const Home = lazy(
  () => import(/* webpackChunkName: "HomeComponent" */ 'features/home/Home')
)
const Troubleshoot = lazy(
  () =>
    import(
      /* webpackChunkName: "TroubleshootComponent" */ 'components/Troubleshoot'
    )
)
const About = lazy(
  () => import(/* webpackChunkName: "AboutComponent" */ 'components/About')
)
const Profile = lazy(
  () =>
    import(
      /* webpackChunkName: "ProfileComponent" */ 'features/profile/Profile'
    )
)
const Login = lazy(
  () => import(/* webpackChunkName: "LoginComponent" */ 'features/auth/Login')
)
const ResetPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "ResetPasswordComponent" */ 'features/auth/ResetPassword'
    )
)
const ForgotPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordComponent" */ 'features/auth/ForgotPassword'
    )
)
const Trips = lazy(
  () => import(/* webpackChunkName: "TripsComponent" */ 'features/trips/Trips')
)
const Employees = lazy(
  () =>
    import(
      /* webpackChunkName: "EmployeesComponent" */ 'features/employees/Employees'
    )
)
const Policies = lazy(
  () =>
    import(
      /* webpackChunkName: "PoliciesComponent" */ 'features/policies/Policies'
    )
)
const LogisticPolicies = lazy(
  () =>
    import(
      /* webpackChunkName: "LogisticPoliciesComponent" */ 'features/logistic/policies/Policies'
    )
)
const FoodPolicies = lazy(
  () =>
    import(
      /* webpackChunkName: "FoodPoliciesComponent" */ 'features/food/policies/Policies'
    )
)
const Payments = lazy(
  () =>
    import(
      /* webpackChunkName: "PaymentsComponent" */ 'features/payments/Payments'
    )
)
const Vouchers = lazy(
  () =>
    import(
      /* webpackChunkName: "VouchersComponent" */ 'features/vouchers/Vouchers'
    )
)
const FoodVouchers = lazy(
  () =>
    import(
      /* webpackChunkName: "FoodVouchersComponent" */ 'features/food/vouchers/Vouchers'
    )
)
const Settings = lazy(
  () =>
    import(
      /* webpackChunkName: "SettingsComponent" */ 'features/settings/Settings'
    )
)
const VerifyUser = lazy(
  () =>
    import(
      /* webpackChunkName: "VerifyUserComponent" */ 'features/verify-user/VerifyUser'
    )
)
const HelpCenterHome = lazy(
  () =>
    import(
      /* webpackChunkName: "HelpCenterHomeComponent" */ 'features/helpcenter/home'
    )
)
const HelpCenterDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "HelpCenterDetailComponent" */ 'features/helpcenter/detail'
    )
)
const HelpCenterSearch = lazy(
  () =>
    import(
      /* webpackChunkName: "HelpCenterSearchComponent" */ 'features/helpcenter/search'
    )
)
const HelpCenterCategory = lazy(
  () =>
    import(
      /* webpackChunkName: "HelpCenterCategoryComponent" */ 'features/helpcenter/list/Category'
    )
)
const HelpCenterFAQ = lazy(
  () =>
    import(
      /* webpackChunkName: "HelpCenterFAQComponent" */ 'features/helpcenter/list/FAQ'
    )
)
const VerifyEmployee = lazy(
  () =>
    import(
      /* webpackChunkName: "VerifyEmployeeComponent" */ 'features/verify-employee'
    )
)
const VerifyIndividualEmployee = lazy(
  () =>
    import(
      /* webpackChunkName: "VerifyIndividualEmployeeComponent" */ 'features/verify-individual-employee'
    )
)
const FourOFour = lazy(
  () =>
    import(/* webpackChunkName: "FourOFourComponent" */ 'components/FourOFour')
)

const SidebarLayout = lazy(
  () =>
    import(
      /* webpackChunkName: "SidebarLayoutComponent" */ 'components/Layout/SidebarLayout'
    )
)
const AuthLayout = lazy(
  () =>
    import(
      /* webpackChunkName: "AuthLayoutComponent" */ 'components/Layout/AuthLayout'
    )
)

export const UnauthenticatedRoute = ({
  children,
  ...props
}: RouterLayoutProps) => <Route {...props}>{children}</Route>

export const AuthenticatedRoute = ({
  children,
  ...props
}: RouterLayoutProps) => {
  const { justLoggedOut } = useSelector(authSelector)
  const isAuthenticated = useSelector(isLoggedIn)

  return (
    <Route
      {...props}
      render={({ location }) => {
        const from = justLoggedOut ? '/' : location
        return isAuthenticated ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from }
            }}
          />
        )
      }}
    ></Route>
  )
}

function App() {
  useSessionSync()
  const { companyId } = useSelector(profileSelector)
  const { profile } = useSelector(authSelector)
  const authenticated = useSelector(isLoggedIn)
  const dispatch = useDispatch()
  useEffect(() => {
    if (authenticated && companyId !== null) {
      configuration.ENV === 'production' &&
        Sentry.setUser({ id: profile?.user_id, company_id: companyId })
      dispatch(getHelpCenterList())
      if (companyId > 0) {
        dispatch(getGroups())
        dispatch(getCompanyDetail())
        dispatch(getNotificationCount())
      }
    }
  }, [authenticated, dispatch, companyId, profile])

  return (
    <Router>
      <Routes />
    </Router>
  )
}

export const routes = [
  {
    hasWhiteBackground: true,
    path: '/',
    component: Home,
    exact: true,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/(limit|rules)',
    component: Policies,
    exact: true,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/limit/delivery',
    component: LogisticPolicies,
    exact: true,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/(limit/food|rules/food)',
    component: FoodPolicies,
    exact: true,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/profile',
    component: Profile,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/trips',
    component: Trips,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/vouchers',
    component: Vouchers,
    exact: true,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/vouchers/food',
    component: FoodVouchers,
    exact: true,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/(payments|payments/method)',
    component: Payments,
    exact: true,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/(settings|settings/sftp|settings/transport)',
    component: Settings,
    exact: true,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/(employees|groups)',
    component: Employees,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/help',
    component: HelpCenterHome,
    auth: true,
    exact: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/help/search',
    component: HelpCenterSearch,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/help/faq',
    component: HelpCenterFAQ,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/help/category/:slug',
    component: HelpCenterCategory,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: true,
    path: '/help/:slug',
    component: HelpCenterDetail,
    auth: true,
    layout: SidebarLayout
  },
  {
    hasWhiteBackground: false,
    path: '/login',
    component: Login,
    auth: false,
    layout: AuthLayout
  },
  {
    hasWhiteBackground: false,
    path: '/accounts/password/reset',
    component: ResetPassword,
    auth: false,
    layout: AuthLayout
  },
  {
    hasWhiteBackground: false,
    path: '/accounts/password/forgot',
    component: ForgotPassword,
    auth: false,
    layout: AuthLayout
  },
  {
    hasWhiteBackground: false,
    path: '/verify-user',
    component: VerifyUser,
    auth: false,
    layout: AuthLayout
  },
  {
    hasWhiteBackground: true,
    path: '/verify-employee',
    component: VerifyEmployee,
    auth: false
  },
  {
    hasWhiteBackground: true,
    path: '/verify-individual-employee',
    component: VerifyIndividualEmployee,
    auth: false
  },
  {
    hasWhiteBackground: false,
    path: '/troubleshoot',
    component: Troubleshoot,
    auth: false
  },
  {
    hasWhiteBackground: false,
    path: '/about',
    component: About,
    auth: false
  },
  { hasWhiteBackground: false, path: '*', component: FourOFour, auth: false }
]

function Routes() {
  usePageviews()
  return (
    <Suspense fallback={<></>}>
      <Switch>
        {routes.map(
          (
            {
              auth,
              component: Component,
              layout: Layout,
              path,
              exact,
              hasWhiteBackground
            },
            id
          ) => {
            const RouteComponent = (props: RouterLayoutProps) =>
              auth ? (
                <AuthenticatedRoute {...props} />
              ) : (
                <UnauthenticatedRoute {...props} />
              )
            const ChildrenComponent = (
              <Suspense fallback={<></>}>
                <Component />
              </Suspense>
            )
            const LayoutComponent = ({
              path,
              exact,
              hasWhiteBackground
            }: RouterLayoutProps) => {
              if (!hasWhiteBackground) {
                document.body.style.background = 'rgb(45, 46, 52)'
              } else {
                document.body.style.background = 'white'
              }
              return Layout ? (
                <Layout>
                  <RouteComponent
                    path={path}
                    exact={exact}
                    children={ChildrenComponent}
                  />
                </Layout>
              ) : (
                <RouteComponent
                  path={path}
                  exact={exact}
                  children={ChildrenComponent}
                />
              )
            }

            return (
              <LayoutComponent
                hasWhiteBackground={hasWhiteBackground}
                key={id}
                path={path}
                exact={exact}
              />
            )
          }
        )}
      </Switch>
    </Suspense>
  )
}

export default App
