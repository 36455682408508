import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { getDashboardAPI } from 'utils/api'
import { RootState } from 'app/store'
import { ErrorData, ErrorState } from 'features/error/errorSlice'

export interface OverviewData {
  employee: {
    active_employee: number
    total_employee: number
  }
  ride: {
    by_total_employee: number
    total_complete_rides: number
  }
  spent: {
    by_total_employee: number
    total_spent: number
    transport_spent: number
    logistic_spent: number
    food_spent: number
    cc_spent: BreakdownSpentByScope
    post_paid_spent: BreakdownSpentByScope
  }
  company_limit: {
    amount: number
    balance: number
    is_unlimited: boolean
  }
}

export interface BreakdownSpentByScope {
  total_spent: number
  transport_spent: number
  logistic_spent: number
  food_spent: number
}

export interface FreData {
  employee_or_group: boolean
  onboarding_preview: boolean
  policy: boolean
}

export interface CompanySummaryData {
  currency_code: string
  billing_types: string[]
  company_usage: {
    spending: string
    limit: {
      amount: string
      is_unlimited: boolean
    }
  }
  employee_stats: {
    active: number
    invited: number
    total: number
  }
  user_stats: Array<{
    role_name: string
    active: number
    invited: number
    total: number
  }>
}

export interface AllSpendingData {
  currency_code: string
  transaction_stats: Array<{
    Key: string
    Total: string
  }>
}

interface BreakdownData {
  Key: string
  Transport: string
  Delivery: string
  Food: string
}

export interface BookingsBreakdownData {
  currency_code: string
  booking_stats_breakdown: BreakdownData[]
}

export interface TransactionsBreakdownData {
  currency_code: string
  transaction_stats_breakdown: BreakdownData[]
}

export interface HomeState {
  isLoading: boolean
  overview: OverviewData
  fre: FreData
  errors: ErrorData
  consolidated: {
    companySummary: {
      isLoading: boolean
      data: CompanySummaryData
      errors: ErrorData
    }
    allSpending: {
      isLoading: boolean
      data: AllSpendingData
      errors: ErrorData
    }
    bookingsBreakdown: {
      isLoading: boolean
      data: BookingsBreakdownData
      errors: ErrorData
    }
    transactionsBreakdown: {
      isLoading: boolean
      data: TransactionsBreakdownData
      errors: ErrorData
    }
  }
}

const initialState: HomeState = {
  isLoading: true,
  overview: {} as OverviewData,
  fre: {} as FreData,
  errors: null,
  consolidated: {
    companySummary: {
      isLoading: true,
      data: {} as CompanySummaryData,
      errors: null
    },
    allSpending: {
      isLoading: true,
      data: {} as AllSpendingData,
      errors: null
    },
    bookingsBreakdown: {
      isLoading: true,
      data: {} as BookingsBreakdownData,
      errors: null
    },
    transactionsBreakdown: {
      isLoading: true,
      data: {} as TransactionsBreakdownData,
      errors: null
    }
  }
}

export const getDashboard = createAsyncThunk(
  'home/getDashboard',
  async (payload: any, { rejectWithValue }) => {
    try {
      return await getDashboardAPI(payload)
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state, action) => {
        const { meta } = action
        if (meta?.arg?.section === 'company_summary') {
          state.consolidated.companySummary.isLoading = true
          return
        }
        if (meta?.arg?.section === 'transactions') {
          state.consolidated.allSpending.isLoading = true
          return
        }
        if (meta?.arg?.section === 'bookings_breakdown') {
          state.consolidated.bookingsBreakdown.isLoading = true
          return
        }
        if (meta?.arg?.section === 'transactions_breakdown') {
          state.consolidated.transactionsBreakdown.isLoading = true
          return
        }
        state.isLoading = true
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        const { meta, payload } = action
        if (meta?.arg?.section === 'company_summary') {
          state.consolidated.companySummary.isLoading = false
          state.consolidated.companySummary.errors = null
          state.consolidated.companySummary.data = payload.data.consolidated
          return
        }
        if (meta?.arg?.section === 'transactions') {
          state.consolidated.allSpending.isLoading = false
          state.consolidated.allSpending.errors = null
          state.consolidated.allSpending.data = payload.data.consolidated
          return
        }
        if (meta?.arg?.section === 'bookings_breakdown') {
          state.consolidated.bookingsBreakdown.isLoading = false
          state.consolidated.bookingsBreakdown.errors = null
          state.consolidated.bookingsBreakdown.data = payload.data.consolidated
          return
        }
        if (meta?.arg?.section === 'transactions_breakdown') {
          state.consolidated.transactionsBreakdown.isLoading = false
          state.consolidated.transactionsBreakdown.errors = null
          state.consolidated.transactionsBreakdown.data =
            payload.data.consolidated
          return
        }
        const { company_statistics, first_run_experience } = payload.data
        state.isLoading = false
        state.errors = null
        state.overview = company_statistics
        state.fre = first_run_experience
      })
      .addCase(
        getDashboard.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          const { meta, payload, error } = action
          if (meta?.arg?.section === 'company_summary') {
            state.consolidated.companySummary.isLoading = false
            state.consolidated.companySummary.errors = payload || {
              status: 500,
              data: `${error.name}: ${error.message}`
            }
            return
          }
          if (meta?.arg?.section === 'transactions') {
            state.consolidated.allSpending.isLoading = false
            state.consolidated.allSpending.errors = payload || {
              status: 500,
              data: `${error.name}: ${error.message}`
            }
            return
          }
          if (meta?.arg?.section === 'bookings_breakdown') {
            state.consolidated.bookingsBreakdown.isLoading = false
            state.consolidated.bookingsBreakdown.errors = payload || {
              status: 500,
              data: `${error.name}: ${error.message}`
            }
            return
          }
          if (meta?.arg?.section === 'transactions_breakdown') {
            state.consolidated.transactionsBreakdown.isLoading = false
            state.consolidated.transactionsBreakdown.errors = payload || {
              status: 500,
              data: `${error.name}: ${error.message}`
            }
            return
          }
          state.isLoading = false
          state.errors = payload || {
            status: 500,
            data: `${error.name}: ${error.message}`
          }
        }
      )
  }
})

export const homeSelector = (state: RootState) => state.home

export const homeConsolidatedCompanySummarySelector = (state: RootState) =>
  state.home.consolidated.companySummary

export const homeConsolidatedAllSpendingSelector = (state: RootState) =>
  state.home.consolidated.allSpending

export const homeConsolidatedBookingsBreakdownSelector = (state: RootState) =>
  state.home.consolidated.bookingsBreakdown

export const homeConsolidatedTransactionsBreakdownSelector = (
  state: RootState
) => state.home.consolidated.transactionsBreakdown

export default homeSlice.reducer
