/** @jsx jsx */
import { jsx } from '@emotion/core'
import tw from 'twin.macro'

type SizeType = {
  s: string
  m: string
  l: string
}

export default function GoCorp({
  linkTo,
  size,
  light,
  version
}: {
  linkTo?: string
  size: 's' | 'm' | 'l'
  light: boolean
  version: 'v1' | 'v2'
}) {
  const svgSize: SizeType = {
    s: '16',
    m: '36',
    l: '48'
  }

  const GoCorpV2SVG = () => {
    return (
      <svg
        viewBox="0 0 304.5 94.6"
        role="img"
        aria-label="GoCorp Logo"
        height={svgSize[size]}
        css={[
          light
            ? tw`fill-current text-white`
            : tw`fill-current text-gocorp-black80`
        ]}
      >
        <g>
          <path d="M116.7,33.1c0.3,0,0.6,0.3,0.6,0.6l0,29.2c0,0,0,0,0,0.1v3c0,8.5-6.9,15.4-15.4,15.4h-1.9   c-6,0-11.1-2.1-14.9-6c-0.2-0.2-0.2-0.6,0-0.9l4.5-4.4c0.3-0.2,0.7-0.2,0.9,0c2.1,2.4,5.6,3.9,9.4,3.9h0c2.6,0,4.9-0.8,6.5-2.1   c1.9-1.6,2.9-4,2.9-6.8v-1.7c-1.2,1.4-3.1,2.2-3.9,2.5c-1.7,0.6-3.5,0.9-5.4,0.9c-9.7,0-17.5-8.1-17.1-17.9   c0.4-8.7,7.4-15.8,16.1-16.3c2.2-0.1,4.4,0.2,6.4,0.9c0.8,0.3,2.7,1,3.9,2.5v-1.3l0,0v-0.8c0-0.3,0.3-0.6,0.6-0.6H116.7z    M109.2,49.6c-0.1-5.1-4.2-9.2-9.3-9.2c-5.2,0-9.3,4.2-9.3,9.3c0,5.2,4.2,9.3,9.3,9.3c5.1,0,9.3-4.1,9.3-9.2V49.6z" />
          <path d="M156.7,49.8c0,9.4-7.6,17.1-17.1,17.1s-17.1-7.6-17.1-17.1c0-9.4,7.6-17.1,17.1-17.1S156.7,40.4,156.7,49.8z    M149.1,49.8c0-5.2-4.2-9.4-9.4-9.4s-9.4,4.2-9.4,9.4c0,5.2,4.2,9.4,9.4,9.4C144.9,59.2,149.1,55,149.1,49.8z" />
          <path d="M192.1,58.9c-3,4.8-8.4,8-14.5,8c-9.7,0-17.6-8.1-17.1-18c0.4-8.7,7.5-15.8,16.2-16.3c6.5-0.3,12.3,3,15.5,8   c0.2,0.3,0.1,0.7-0.2,0.9l-5.6,3.2c-0.3,0.2-0.6,0.1-0.8-0.2c-1.7-2.6-4.6-4.3-7.9-4.3c-5.6,0-10,4.8-9.4,10.5   c0.5,4.3,3.9,7.8,8.3,8.3c3.7,0.4,7.1-1.3,8.9-4.1c0.2-0.3,0.5-0.3,0.8-0.2l5.6,3.2C192.2,58.2,192.3,58.6,192.1,58.9z" />
          <path d="M229.7,49.8c0,9.4-7.6,17.1-17.1,17.1c-9.4,0-17.1-7.6-17.1-17.1c0-9.4,7.6-17.1,17.1-17.1   C222.1,32.7,229.7,40.4,229.7,49.8z M222.1,49.8c0-5.2-4.2-9.4-9.4-9.4s-9.4,4.2-9.4,9.4c0,5.2,4.2,9.4,9.4,9.4   C217.9,59.2,222.1,55,222.1,49.8z" />
          <path d="M258.1,33.5l-1.5,6.6c0,0.2-0.2,0.3-0.3,0.3c0,0-0.1,0-0.1,0c-1.4-0.3-2.5-0.5-4.1-0.5   c-5.6,0-8.7,3.8-8.7,10.8v14.9c0,0.3-0.3,0.6-0.6,0.6h-6.9c-0.3,0-0.6-0.3-0.6-0.6V33.7c0-0.3,0.3-0.6,0.6-0.6h6.4   c0.3,0,0.6,0.3,0.6,0.6v3.8l1-1.3l0.1-0.1c2.4-2.4,5.6-3.7,9.4-3.7c1.4,0,2.9,0.2,4.5,0.6C258,33.2,258.1,33.4,258.1,33.5z" />
          <path d="M296.7,49.3c0.1,9.6-7.6,17.4-17.1,17.4c-3.4,0-6.6-1-9.3-2.7l0,15.5c0,0.3-0.3,0.6-0.6,0.6h-6.8   c-0.3,0-0.6-0.3-0.6-0.6V33.7c0-0.3,0.3-0.6,0.6-0.6h6.7c0.3,0,0.6,0.3,0.6,0.6v1.5c2.7-1.7,5.9-2.7,9.4-2.7   C288.9,32.5,296.6,40.1,296.7,49.3z M279.6,40.3c-4.7,0-8.6,3.5-9.2,8c0,0.2,0,0.4,0,0.6v1.4c0,0.2,0,0.4,0,0.6   c0.6,4.7,4.8,8.3,9.7,8c4.7-0.2,8.6-4,8.9-8.7C289.2,44.8,284.9,40.3,279.6,40.3z" />
        </g>
        <g>
          <g>
            <path
              fill="#00aa13"
              d="M35,20.5C20,21.2,7.7,33.3,6.9,48.4c-0.7,12.4,6.3,23.2,16.6,28.2c3.8,1.9,8.4-0.5,9.1-4.7l0,0    c0.5-2.8-0.9-5.6-3.5-6.9c-5.9-2.9-9.8-9.1-9.3-16.1c0.5-8.2,7.2-15,15.5-15.5c9.8-0.7,17.9,7,17.9,16.7c0,6.6-3.8,12.3-9.4,15    c-2.5,1.2-3.9,4-3.4,6.8c0,0.1,0,0.1,0,0.1c0.7,4.1,5.2,6.5,9,4.7C59.2,71.9,66,61.7,66,50C66,33.2,52,19.7,35,20.5L35,20.5z"
            />
          </g>
          <g>
            <path
              fill="#00aa13"
              d="M36.5,60.5C30.7,60.5,26,55.8,26,50s4.7-10.4,10.4-10.4S46.9,44.3,46.9,50S42.2,60.5,36.5,60.5z"
            />
          </g>
        </g>
      </svg>
    )
  }

  const GoCorpSVG = () => {
    return (
      <svg
        role="img"
        aria-label="GoCorp Logo"
        height={svgSize[size]}
        viewBox="0 0 150 28"
        css={[
          light
            ? tw`fill-current text-white`
            : tw`fill-current text-gocorp-black80`
        ]}
      >
        <title>GoCorp Logo</title>
        <circle cx="12.2" cy="12.4" fill="#00aed6" r="11.9" />
        <g fill="#fff">
          <path d="m19.9 15.2-1.1-.9c-.1-.1-.2-.1-.3-.1s-.2.1-.2.2l-.3 1.5c0 .1 0 .3.1.3.1.1.2.1.3.1l.2-.1c.2.9-.3 1.7-1.1 2-.4.1-.8.1-1.2 0-.9-.4-1.3-1.4-.9-2.3.3-.8 1.1-1.2 2-1 .1 0 .2 0 .2-.1l.1-.5c0-.1 0-.1 0-.2 0 0-.1-.1-.1-.1-.4-.1-.8-.1-1.1 0-.6.1-1.2.5-1.6 1-.8 1-.8 2.4 0 3.4.5.6 1.2 1 2.1 1 .4 0 .8-.1 1.2-.3 1.1-.6 1.7-1.9 1.4-3.1l.3-.1c.1 0 .2-.1.2-.3s-.1-.3-.2-.4z" />
          <path d="m8.9 7.1c1.8.1 3.7.1 5.5 0 .1 0 .2-.1.2-.2.1-.4.2-.8.2-1.2 0-.7-.5-1.2-1.1-1.3-1.3-.1-2.7-.1-4 0-.6.1-1.1.6-1.2 1.2 0 .4 0 .9.2 1.3 0 .1.1.2.2.2z" />
          <path d="m17 12.8c.3 0 .6 0 .9.1 0-1.5-.1-3-.2-4.4-.2-1.1-1-1.9-2.1-2 0 .4-.1.8-.2 1.2 0 .1-.1.2-.3.2-2.3.1-4.5.1-6.8-.1-.1 0-.2-.1-.3-.2-.1-.4-.1-.8-.1-1.2-1.1.1-1.9 1-2.1 2-.3 2.8-.3 5.7 0 8.6.2 1.1 1.1 2 2.3 2.1 2.1.3 4.2.3 6.3.1-1.5-1.5-1.4-3.9 0-5.3.6-.7 1.6-1.1 2.6-1.1zm-5 1h-3.6c-.3 0-.5-.3-.4-.6 0-.2.2-.4.4-.4h3.6c.3 0 .5.3.4.6 0 .2-.2.4-.4.4zm-3.5-2.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h6.5c.3 0 .5.2.5.5s-.2.5-.5.5z" />
        </g>
        <path d="m31.1 20.4 2.7-2.2c1 1.5 2.7 2.3 4.5 2.2 2.1 0 3.6-1.3 3.6-3.1v-1h-.1c-1.1 1.1-2.7 1.7-4.3 1.6-3.9.1-7.1-2.9-7.2-6.8s2.9-7.1 6.8-7.2h.3c1.6-.1 3.2.5 4.4 1.6h.1v-1.3h3.6v13c0 3.8-3 6.4-7.2 6.4-2.8.2-5.4-1-7.2-3.2zm10.6-8.7v-1.6c0-1.5-1.7-2.9-3.6-2.9-2.4 0-4 1.5-4 3.7-.1 1.9 1.3 3.6 3.3 3.8h.6c2.1 0 3.7-1.4 3.7-3z" />
        <path d="m47.3 10.9c0-4 3.1-7.1 7.5-7.1s7.5 3.1 7.5 7.1-3.1 7.1-7.5 7.1c-4.3 0-7.5-3.1-7.5-7.1zm11.3 0c0-2.1-1.7-3.8-3.8-3.8s-3.8 1.7-3.8 3.8 1.7 3.8 3.8 3.8c2 .1 3.7-1.4 3.8-3.4.1-.2.1-.3 0-.4z" />
        <path d="m63.9 10.9c0-3.9 3.2-7.1 7.1-7.1h.3c3.4 0 6.1 1.9 6.9 4.7l-3.5 1c-.5-1.5-1.9-2.5-3.5-2.4-2.1.1-3.7 1.8-3.7 3.9.1 2 1.7 3.6 3.7 3.7 1.6.1 3-.9 3.4-2.4l3.5 1c-.8 2.8-3.5 4.7-6.9 4.7-3.9.2-7.2-2.9-7.4-6.8.1-.1.1-.2.1-.3z" />
        <path d="m79.4 10.9c0-4 3.1-7.1 7.5-7.1s7.5 3.1 7.5 7.1-3.1 7.1-7.5 7.1c-4.3 0-7.5-3.1-7.5-7.1zm11.4 0c0-2.1-1.7-3.8-3.8-3.8s-3.8 1.7-3.8 3.8 1.7 3.8 3.8 3.8c2 .1 3.7-1.4 3.8-3.4 0-.2 0-.3 0-.4z" />
        <path d="m96.5 4.2h3.5v1.3c1-1 2.5-1.6 3.9-1.5.7 0 1.5.1 2.2.4l-.8 3.4c-.6-.2-1.3-.3-1.9-.3-2 0-3.2 1.3-3.2 4.1v6.1h-3.7z" />
        <path d="m107.6 4.2h3.6v1.2h.1c1.2-1.1 2.7-1.6 4.3-1.6 3.9.1 7 3.3 6.9 7.2-.1 3.8-3.1 6.8-6.9 6.9-1.5 0-3-.5-4.2-1.4v6.7h-3.7v-19zm11.1 6.7c.1-2-1.4-3.7-3.4-3.8-.1 0-.3 0-.4 0-2 0-3.6 1.3-3.6 2.9v1.7c0 1.6 1.6 2.9 3.7 2.9 2 .1 3.7-1.5 3.8-3.4-.1-.1-.1-.2-.1-.3z" />
      </svg>
    )
  }

  const SVG = version === 'v2' ? GoCorpV2SVG : GoCorpSVG

  return linkTo ? (
    <a href={linkTo}>
      <SVG />
    </a>
  ) : (
    <SVG />
  )
}

GoCorp.defaultProps = {
  size: 'm',
  light: false,
  version: 'v2'
}
