import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { toTimestampTz } from 'utils/date'
import { getTripsAPI } from 'utils/api'
import { SelectionType } from 'utils/constant/filterStatus'
import { ErrorData, ErrorState } from 'features/error/errorSlice'
import { RootState } from 'app/store'
import { TripConfigData } from 'features/settings/tripDetailSettingSlice'
import {
  FoodPolicyScope,
  PolicyScope,
  TransportPolicyScope
} from 'features/policies/policySlice'

export interface FareBreakDownData {
  amount: number
  description: string
  display_name: string
  type: string
}

export interface FoodPricingInfo {
  v1: {
    payment_method_details: PaymentMethodDetails
    line_items: LineItems
  }
}

export interface LineItems {
  rows: Array<LineItem>
}

export interface LineItem {
  id: string
  title: string
  value: string
  prev_value: string
}

export interface PaymentMethodDetails {
  total: string
  prev_total: string
  methods: Array<PaymentMethod>
  edit_info: PaymentMerchantEditInfo
}

export interface PaymentMethod {
  amount: string
  method: string
  display_name: string
  network: string
}

export interface PaymentMerchantEditInfo {
  icon_url: string
  text: string
}

export interface ShoppingItem {
  quantity: number
  name: string
}

export interface BookingDestinationData {
  id?: string
  address: string
  address_details: string
  location_name: string
  distance_in_kms: number
  name?: string
  received_by?: string
  pickup_time: string
  type: string
}

export interface BookingProofData {
  reference_id: string
  address: string
  address_details: string
  location_name: string
  distance_in_kms?: number
  url: string
  received_by?: string
  pickup_time?: string
  completed_at?: string
}

export interface BookingData {
  id: number
  booking_creation_time: string
  base_fare: number
  booking_completion_time: string
  total_distance_in_kms: number
  drop: string
  employee_name: string
  employee_number: string
  company_name: string
  group_name: string
  order_number: string
  pickup: string
  pricing_currency: string
  service_type: string
  fare_break_down: Array<FareBreakDownData>
  total_fare: number
  scope: PolicyScope
  destinations: Array<BookingDestinationData>
  vehicle_plate_number: string
}

export interface TransportBookingData extends BookingData {
  scope: TransportPolicyScope
  business_booking: {
    trip_reason: string
    trip_code: string
    trip_description: string
    ride_share: number
  }
  voucher_code: string
  voucher_program_name: string
  passenger_name: string
  passenger_phone: string
}

export interface FoodBookingData extends BookingData {
  scope: FoodPolicyScope
  food_details: {
    shopping_items: Array<ShoppingItem>
    pricing_info: FoodPricingInfo
  }
}

export interface TripState {
  isLoading: boolean
  trips: Array<BookingData>
  tripConfig: TripConfigData
  total: number
  checkedTripIds: Array<number>
  itemsPerPage: number
  currentPage: number
  filters: {
    groupIds: Array<string>
    companyIds: Array<string>
    fromTime: string | null
    toTime: string | null
    query: string
    scope: Array<string>
    usage: Array<string>
    platform: Array<string>
  }
  isAllPagesSelected: boolean
  isFilterActive: boolean
  errors: ErrorData
}

export const initialState: TripState = {
  isLoading: false,
  trips: [],
  tripConfig: {
    logo_url: '',
    trip_code_feature_enabled: false,
    trip_code_required: false,
    trip_reason_required: false,
    trip_reasons: [],
    trip_description_required: false,
    ride_share_feature_enabled: false,
    ride_share_info_required: false
  },
  total: 0,
  checkedTripIds: [],
  itemsPerPage: 10,
  currentPage: 1,
  filters: {
    groupIds: [],
    companyIds: [],
    fromTime: null,
    toTime: null,
    query: '',
    scope: [],
    usage: [],
    platform: []
  },
  isAllPagesSelected: false,
  isFilterActive: false,
  errors: null
}

export const getTrips = createAsyncThunk(
  'trip/getTrips',
  async (payload: any, { getState, rejectWithValue }) => {
    try {
      const { filters } = (getState() as RootState).trip
      if (filters.fromTime !== null) {
        payload.from_time = toTimestampTz(
          new Date(filters.fromTime),
          'START',
          payload.companyCC
        )
      }
      if (filters.toTime !== null) {
        payload.to_time = toTimestampTz(
          new Date(filters.toTime),
          'END',
          payload.companyCC
        )
      }
      if (filters.groupIds.length > 0) {
        payload.group_ids = filters.groupIds.join()
      }
      if (filters.companyIds.length > 0) {
        payload.company_ids = filters.companyIds.join()
      }
      if (filters.scope.length > 0) {
        payload.scope = filters.scope.join()
      }
      if (filters.usage.length > 0) {
        payload.usage = filters.usage.join()
      }
      if (filters.platform.length > 0) {
        payload.platform = filters.platform.join()
      }
      payload.query = filters.query
      const { companyCC, ...filteredPayload } = payload
      return await getTripsAPI(filteredPayload)
    } catch (error) {
      const { status, data } = error.response
      if (data || status) return rejectWithValue({ data, status })
      return error
    }
  },
  {
    condition: (_, { getState }) => {
      const { error } = getState() as {
        error: ErrorState
      }
      if (
        error.status === 429 &&
        Math.floor(Date.now() / 1000) <= error.updateAt!
      ) {
        return false
      }
    },
    dispatchConditionRejection: true
  }
)

export const tripSlice = createSlice({
  name: 'trip',
  initialState: initialState,
  reducers: {
    setFilterGroup: (state, action) => {
      const { payload: value } = action
      const {
        filters: { groupIds }
      } = state
      if (value === SelectionType.ALL) {
        state.filters.groupIds = initialState.filters.groupIds
      } else if (groupIds.includes(value)) {
        let filterGroup = groupIds.filter((id) => id !== value)
        state.filters.groupIds = filterGroup
      } else {
        state.filters.groupIds = [...groupIds, value]
      }
    },
    setFilterCompany: (state, action) => {
      const { payload: value } = action
      const {
        filters: { companyIds }
      } = state
      if (companyIds.includes(value)) {
        let filterCompanyIds = companyIds.filter((id) => id !== value)
        state.filters.companyIds = filterCompanyIds
      } else {
        state.filters.companyIds = [...companyIds, value]
      }
    },
    setFilterQuery: (state, action) => {
      state.filters = {
        ...initialState.filters,
        query: action.payload
      }
    },
    setFilter: (state, action) => {
      const { payload } = action
      if (!payload) return
      state.filters = {
        ...state.filters,
        ...payload
      }
      state.currentPage = initialState.currentPage
    },
    setFilterUsage: (state, action) => {
      const { payload: value } = action
      const {
        filters: { usage }
      } = state
      if (usage.includes(value)) {
        let filterUsage = usage.filter((id) => id !== value)
        state.filters.usage = filterUsage
      } else {
        state.filters.usage = [...usage, value]
      }
    },
    setFilterPlatform: (state, action) => {
      const { payload: value } = action
      const {
        filters: { platform }
      } = state
      if (platform.includes(value)) {
        let filterPlatform = platform.filter((id) => id !== value)
        state.filters.platform = filterPlatform
      } else {
        state.filters.platform = [...platform, value]
      }
    },
    setFilterScope: (state, action) => {
      const { payload: value } = action
      const {
        filters: { scope }
      } = state
      if (scope.includes(value)) {
        let filterScope = scope.filter((id) => id !== value)
        state.filters.scope = filterScope
      } else {
        state.filters.scope = [...scope, value]
      }
    },
    setFromTime: (state, action) => {
      state.filters.fromTime = action.payload
    },
    setToTime: (state, action) => {
      state.filters.toTime = action.payload
    },
    resetFilter: (state, action) => {
      if (!action.payload) {
        state.filters = initialState.filters
        return
      }
      const {
        groupIds,
        scope,
        companyIds,
        fromTime,
        toTime,
        query,
        usage,
        platform
      } = action.payload
      if (groupIds) {
        state.filters.groupIds = initialState.filters.groupIds
      }
      if (scope) {
        state.filters.scope = initialState.filters.scope
      }
      if (companyIds) {
        state.filters.companyIds = initialState.filters.companyIds
      }
      if (fromTime) {
        state.filters.fromTime = initialState.filters.fromTime
      }
      if (toTime) {
        state.filters.toTime = initialState.filters.toTime
      }
      if (query) {
        state.filters.query = initialState.filters.query
      }
      if (usage) {
        state.filters.usage = initialState.filters.usage
      }
      if (platform) {
        state.filters.platform = initialState.filters.platform
      }
      state.currentPage = initialState.currentPage
    },
    resetFilterGroup: (state) => {
      state.filters.groupIds = initialState.filters.groupIds
    },
    resetFilterScope: (state) => {
      state.filters.scope = initialState.filters.scope
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setItemsPerPage: (state, action) => {
      state.itemsPerPage = action.payload
    },
    resetPagination: (state) => {
      state.itemsPerPage = initialState.itemsPerPage
      state.currentPage = initialState.currentPage
    },
    setCheckedTripIds: (state, action) => {
      state.checkedTripIds = action.payload
    },
    setAllPagesSelected: (state, action) => {
      if (state.isAllPagesSelected) {
        state.checkedTripIds = initialState.checkedTripIds
      }
      state.isAllPagesSelected = action.payload
    },
    resetState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrips.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTrips.fulfilled, (state, action) => {
        const { total, bookings, trip_config } = action.payload.data
        state.isLoading = false
        state.errors = null
        state.trips = bookings
        state.total = total
        state.tripConfig = trip_config
        state.tripConfig.trip_reasons = trip_config.trip_reasons || []
      })
      .addCase(
        getTrips.rejected,
        (state, action: PayloadAction<any, string, any, any>) => {
          state.trips = initialState.trips
          state.total = initialState.total
          state.tripConfig = initialState.tripConfig
          state.isLoading = false
          state.errors = action.payload || {
            status: 500,
            data: `${action.error.name}: ${action.error.message}`
          }
        }
      )
  }
})

export const {
  setCheckedTripIds,
  setCurrentPage,
  setFromTime,
  setToTime,
  setItemsPerPage,
  setFilter,
  setFilterCompany,
  setFilterQuery,
  setFilterGroup,
  setFilterUsage,
  setFilterScope,
  setFilterPlatform,
  setAllPagesSelected,
  resetFilter,
  resetFilterGroup,
  resetFilterScope,
  resetPagination,
  resetState
} = tripSlice.actions

export const tripSelector = (state: RootState) => state.trip

export default tripSlice.reducer
